var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{staticClass:"mx-auto",staticStyle:{"max-width":"800px"},attrs:{"items":_vm.list,"fields":_vm.fields,"store":"helper.support_types","resource":"support-types","creatable":"","noFilter":""},on:{"click-create":function($event){_vm.showCreateType = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"d-flex px-2",attrs:{"col":"12"}},[_c('TButtonEdit',{on:{"click":function($event){return _vm.editItem(item)}}}),_c('TButton',{attrs:{"icon":"cil-list","size":"sm","tooltip":"Authorization","variant":"ghost"},on:{"click":function($event){return _vm.editPermission(item)}}}),_c('TButtonDelete',{on:{"click":function($event){return _vm.removeType(item.id)}}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.name}})],1)]}},{key:"parent",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.parent)?_c('TMessageText',{attrs:{"value":item.parent.name}}):_vm._e()],1)]}},{key:"role",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.role_support_types),function(role){return _c('div',{key:role.id},[_c('SMessageRole',{attrs:{"id":role.role_id}})],1)}),0)]}}])}),_c('SupportTypeModal',{attrs:{"show":_vm.showCreateType},on:{"updated":_vm.fetchSupports,"update:show":function($event){_vm.showCreateType=$event}}}),_c('SupportTypeModal',{attrs:{"supportType":_vm.detail,"show":_vm.showEditType},on:{"updated":_vm.fetchSupports,"update:show":function($event){_vm.showEditType=$event}}}),_c('RoleSupportTypeModal',{attrs:{"supportType":_vm.detail,"show":_vm.showPermission},on:{"updated":_vm.fetchRoleSpType,"update:show":function($event){_vm.showPermission=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }